import '../theme/index.scss'

import { polyfill } from '../helpers/polyfill'

import type { AppProps } from 'next/app'

polyfill()

function _App({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />
}
export default _App
